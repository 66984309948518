body {
   font-family: "Times New Roman", Times, serif;
   font-size: large;
}
.btn {
   font-size: large;
   width: 20%;
}
.small-nav-text {
   font-size: xx-small;
}

@media screen and (min-width: 900px) {
   body {
      font-size: x-large;
   }
   .btn {
      font-size: x-large;
   }
   .hero {
      position: relative;
   }
   .hero .hero-buttons {
      position: absolute;
      top: 60%;
      left: 65%;
      width: 34%;
   }
   .hero-text {
      position: absolute;
      top: 25%;
      left: 65%;
      width: 33%;
      color: white;
      text-shadow: 0 0 3px rgba(50, 50, 50, 0.8), 0 0 6px rgba(50, 50, 50, 0.8);
   }
   .hero-buttons .btn {
      color: white;
      text-shadow: 0 0 1px #cac7c7, 0 0 3px rgba(70, 70, 70, 0.6);
      border-color: white;
      background-color: rgb(0, 0, 0, 0.2);
   }
   .hero .hero-buttons .btn:hover {
      background-color: rgb(0, 0, 0, 0);
   }
}

.fade-in-image {
   animation: fadeIn 4s;
   -webkit-animation: fadeIn 4s;
   -moz-animation: fadeIn 4s;
   -o-animation: fadeIn 4s;
   -ms-animation: fadeIn 4s;
}
@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@-moz-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@-webkit-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@-o-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

@-ms-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.blink {
   animation: blinker 2s linear infinite;
}

@keyframes blinker {
   50% {
      opacity: 0;
   }
}
